import { useAuthStore } from '~/stores/auth'
import { defineNuxtRouteMiddleware, navigateTo } from '#imports'

export default defineNuxtRouteMiddleware(() => {
    const auth = useAuthStore()

    // @ts-expect-error
    if (auth.authenticated && auth.user && !auth.user.organization) {
        return navigateTo('/auth/login')
    }

    // @ts-expect-error
    if (auth.authenticated && auth.user && auth.user?.staff?.access_tablet_only) {
        return navigateTo('/tablet')
    }
})
